<template>
  <div class="columns is-flex is-justify-content-space-between is-mobile is-marginless is-vcentered my-bordered p-4" >
    <div class="column">
      <p class="is-size-4 title has-text-weight-normal">{{ address }}</p>
    </div>  
    <div class="column is-narrow is-paddingless  ">
      <button class="button is-danger is-light box">
        <span class="icon is-medium" v-on:click="select()">
          <i class="fas fa-2x fa-times"></i>
        </span>
      </button>
    </div>
  </div>
</template>

<i18n>
{
  "it": {
  }
}
</i18n>

<script>

  export default {
    name: "mail-verified-item",
    props: {
      address: String,
    },
    methods: {
      select() {
        this.$emit("selected", this.address)
      },
    }
  }

</script>

<style>
</style>