<script>
  import MyMailItem from "@/components/MyMailItem"
  import api from "@/api"
  import EventBus from "@/eventbus"

  export default {
    name: 'mail',
    components: {
      MyMailItem
    },
    data: function() {
      return {
        isLoading: false,
        withErrors: false,
        isDeleteMailModalOpen: false,
        selectedAddress: "",
        mails: []
      }
    },
    props: {
      screenWidth: Number
    },
    created() {
      this.$store.dispatch("pushPage", this.$route.name)
      this.$store.dispatch("resetQuestionnaireState")
      
      this.$store.dispatch("setBackLogic", () => {
        this.$router.replace({name: "home"})
      })
      

      this.loadMailAddresses() 
    },
    methods: {
      loadMailAddresses() {
        this.isLoading = true
        api.getMails().then((response) => {
          this.mails = response.data.addresses
        }).catch(() => {
          EventBus.$emit("errorMessage", "Impossibile reperire le tue e-mail")
        }).finally(() => {
          this.isLoading = false
        })
      },
      openRemoveMailModal(selectedAddress) {
        this.selectedAddress = selectedAddress
        this.isDeleteMailModalOpen = true
      },
      closeRemoveMailModal() {
        this.isDeleteMailModalOpen = false
        this.selectedAddress = ""
      },
      removeMail() {
        var params = { address: this.selectedAddress }
        api.deleteMailAddress(params).then(response => {
          if (response.status==204) {
            this.loadMailAddresses()
          } else {
            EventBus.$emit("errorMessage", "Impossibile eliminare l'indirizzo e-mail specificato")  
          }
        }).catch(() => {
            EventBus.$emit("errorMessage", "Impossibile eliminare l'indirizzo e-mail specificato")
        }).finally(() => {
          this.closeRemoveMailModal()
        })
      }
    },
    computed: {
    }
  }
</script>
<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-5-desktop" v-if="!isLoading">
          <div class="block">
            <h1 class="title is-3">
              {{ $t("title") }}
            </h1> 
          </div>
          <div class="block">
            <div class="message is-info mb-1">
              <div class="message-body">
                {{ $t("message1") }}
              </div>
            </div>
            <div class="message is-info mb-1">
              <div class="message-body">
                {{ $t("message2") }}
              </div>
            </div>
          </div>
          <div class="block" v-if="this.mails.length > 0">
            <p>{{ $t("emails") }}</p>  
            <MyMailItem v-for="(mail, index) in mails"
              v-bind:key="index" 
              v-bind:address="mail"
              v-on:selected = "openRemoveMailModal(mail)"
              />
          </div>
          <div class="block has-text-centered">
            <button class="button is-medium is-fullwidth my-primary-button" v-on:click="$router.replace({name:'mail-add'})">
                {{ $t("newMailButtonText") }}
            </button>      
          </div>
        </div> 
        <div class="column is-4-desktop" v-else>
          <div class="box is-flex is-flex-direction-column is-align-items-center section">
            <div class="block has-text-centered">
              <h1 class="title is-3">
                {{ $t("loadingTitle") }}
              </h1>
              <h2 class="subtitle is-5">
                {{ $t("loadingSubtitle") }}
              </h2>
            </div>
            <div class="loader is-loading my-loader"></div>
          </div>
        </div> 
      </div>
    </div>
    <div class="modal is-active" v-if="isDeleteMailModalOpen">
      <div class="modal-background" v-on:click="closeRemoveMailModal()"></div>
      <div class="modal-card">
        <div class="modal-card-body">
          <div class="modal-card-title block">
            {{ $t("deleteMailTitle") }}
          </div>
          <div class="block">
            <div class="message is-info mb-1">
              <div class="message-body">
                <p> {{ $t("message2") }} </p>
              </div>
            </div>
            <div class="message is-warning">
              <div class="message-body">
                <p> {{ $t("message3") }} </p>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="columns is-multiline is-mobile is-marginless is-vcentered" >
              <div class="column has-text-centered">
                <p class="is-size-4 title has-text-weight-normal"> {{ selectedAddress }}</p>
              </div>  
            </div>
          </div>
          <div class="buttons is-right">
            <button class="button" :class="{'is-fullwidth': screenWidth <= 768}" @click="closeRemoveMailModal()"> Annulla </button>
            <button class="button is-danger" :class="{'is-fullwidth': screenWidth <= 768}" @click="removeMail()"> Conferma </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "it": {
    "title": "Gestisci le tue e-mail",
    "message1": "Il tuo indirizzo e-mail sarà utilizzato unicamente per inviarti il codice di accesso ai luoghi in cui vuoi recarti.",
    "message2": "TRAC19 memorizza il tuo indirizzo e-mail esclusivamente su questo dispositivo per velocizzare la compilazione dei successivi questionari.",
    "deleteMailTitle": "Vuoi rimuovere questo indirizzo e-mail?",
    
    "message3": "Questa e-mail non sarà più disponibile per la compilazione di questionari. In qualsiasi momento potrai registrare di nuovo questa e-mail.",
    
    "message2": "TRAC19 memorizza il tuo indirizzo e-mail esclusivamente su questo dispositivo per velocizzare la compilazione dei successivi questionari.",
    "emails": "E-mail registrate",
    "newMailButtonText": "Registra e-mail",
    "loadingTitle": "Caricamento e-mail",
    "loadingSubtitle": "L'operazione dovrebbe richiedere qualche istante"
  }
}
</i18n>

<style>
</style>